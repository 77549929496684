import { TypographyOptions } from '@mui/material/styles/createTypography'
import { createTheme } from '@mui/material/styles'

const defaultTheme = createTheme()
export const typography: TypographyOptions = {
	htmlFontSize: 16,
	fontFamily: 'Gilroy, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
	h1: {
		fontSize: '48px',
		lineHeight: '47.28px',
		fontWeight: 600,
		[defaultTheme.breakpoints.down('md')]: {
			fontSize: '32px',
			fontWeight: 700,
		},
	},
	h2: {
		fontSize: '40px',
		lineHeight: '39.4px',
		fontWeight: 600,
		[defaultTheme.breakpoints.down('md')]: {
			fontSize: '28px',
			fontWeight: 700,
		},
	},
	h3: {
		fontSize: '32px',
		lineHeight: '37.44px',
		fontWeight: 600,
		[defaultTheme.breakpoints.down('md')]: {
			fontSize: '28px',
			fontWeight: 700,
		},
	},
	h4: {
		fontSize: '28px',
		lineHeight: '33.4px',
		fontWeight: 600,
	},
	h5: {
		fontSize: '24px',
		lineHeight: '28.63px',
		fontWeight: 600,
	},
	h6: {
		fontSize: '18px',
		lineHeight: '21.13px',
		fontWeight: 600,
	},
	body1: {
		fontSize: '16px',
		lineHeight: '22.11px',
		fontWeight: 500,
	},
	subtitle1: {
		fontSize: '22px',
		lineHeight: '33.33px',
		fontWeight: 500,
		opacity: 0.8,
		[defaultTheme.breakpoints.down('md')]: {
			fontSize: '16px',
			lineHeight: '22.11px',
			opacity: 1,
		},
	},
	body2: {
		fontSize: '14px',
		lineHeight: '21.21px',
		fontWeight: 400,
	},
	button: {
		fontSize: '16px',
		lineHeight: '19.09px',
		fontWeight: 500,
	},
	aiSectionTitle: {
		fontSize: '3rem',
		fontWeight: 800,
		lineHeight: '3rem',
	}
	// subtitle2: {},
	// caption: {
	// 	fontSize: '12px',
	// 	fontWeight: 700,
	// },
}
